// Styling markdown output

// Code formatting
pre {
    border-left: $spacer-1 solid $color-border;
    margin: $spacer-3 0;
    padding-left: 16px;
    width: (9 / 12 * 100%); // matches col-9
    overflow-x: auto;
}

code {
    color: $color-code;
    font-size: $body-font-size;
    padding: 1px 0px;
}


.prose {

  a {
    text-decoration: underline dotted;

    &:hover {
      text-decoration: underline;
    }
  }

  p, ol, ul {
    font-size: $prose-font-size;
    margin-bottom: 1em;
    width: 100%;

    @media (min-width: $breakpoint-lg) {
      width: (10 / 12 * 100%); // matches col-10
    }

  }

  ul, ol {
    padding-left: 40px;
  }

  li {
    margin-bottom: 0.5em;

    ul li, ol li {
      margin-bottom: 0;
    }
  }

  img {

    max-width: 100%;

    @media (min-width: $breakpoint-lg) {
      max-width: (12 / 10 * 100%); // make image fill width of container on desktop
    }

  }

  blockquote {
    line-height: 1.375;
    padding-left: 20px;
    margin: 40px 0 40px -16px;
    border-left: $spacer-1 solid $color-border;
    font-style: italic;

    p {
      font-size: 24px;
    }

    @media (min-width: $breakpoint-lg) {
      padding-left: $spacer-3;
      margin: $spacer-4 0 $spacer-4 -40px;
      max-width: (11 / 10 * 100%);

      p {
        font-size: 32px;
      }

    }

  }

  hr {
    color: $color-body-text;
    border-style: solid;
    border-width: thin;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  dt {
    font-weight: bold;
    font-style: italic;
    line-height: 1.25;
  }

  dd {
    font-style: italic;
    margin-bottom: 0.5em;
  }

  // Markdown tables
  table {
    border-collapse: collapse;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    overflow: auto;
    // For Firefox to horizontally scroll wider tables.
    word-break: normal;
    word-break: keep-all;

    th {
      font-weight: bold;
      text-align: left;
    }

    th,
    td {
      padding: $spacer-2 $spacer-3 $spacer-2 2px;
      border-top: 1px solid $color-body-text;
      border-bottom: 1px solid $color-body-text;
    }

    tr {
      background-color: #fff;
      border-top: 1px solid $color-body-text;
    }

    tr th {
      border-top: 2px solid $color-body-text;
      border-bottom: 2px solid $color-body-text;
    }
  }

}
