/**
  * Reset some basic elements
*/

* {
 box-sizing: border-box !important;
 margin: 0; }

input,
select,
textarea,
button {
 font-family: inherit;
 font-size: inherit;
 line-height: inherit;
}


body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}


/**
 * Basic styling
 */
body {
    font-family: $body-font-family;
    font-size: $body-font-size;
    line-height: 1.5;
    color: $color-body-text;
    background-color: #fff;
}

p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
  margin-bottom: 0.25em;
}

h1, .h1 { font-size: $h1-size; }
h2, .h2 { font-size: $h2-size; }
h3, .h3 { font-size: $h3-size; }
h4, .h4 { font-size: $h4-size; }
h5, .h5 { font-size: $h5-size; }
h6, .h6 { font-size: $h6-size; text-transform: uppercase; letter-spacing: 0.02em; }

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
